import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    courseIds: [],
    lectureIds: [],
  },
  mutations: {
    setCourseIds(state, ids) {
      state.courseIds = ids
    },
    setLectureIds(state, ids) {
      state.lectureIds = ids
    },
  },
  actions: {
    loadIds({ commit }) {
      const baseUrl = process.env.VUE_APP_PAGES_URL;
      
      axios.get(`${baseUrl}/courses/get/course_ids`, { withCredentials: true }).then(response => {
        commit('setCourseIds', response.data.course_ids)
      })

      axios.get(`${baseUrl}/lectures/get/lecture_ids`, { withCredentials: true }).then(response => {
        commit('setLectureIds', response.data.lecture_ids)
      })
    },
  },
})
