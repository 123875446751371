
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/common.less'

Vue.config.productionTip = false
Vue.use(ElementUI);

// Load the IDs when the app starts
store.dispatch('loadIds')

// Set up an interval to reload the IDs every 10 minutes
setInterval(() => {
  store.dispatch('loadIds')
}, 10 * 60 * 1000)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
