import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import Layout from "@/layout/Layout";
import store from '../store' // Path to your Vuex store file


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import("@/views/HomeView"),
      },
      {
        path: '/course/:id',
        name: 'CourseView',
        component: () => import("@/views/ListView"),
      },
      {
        path: '/lecture/:id',
        name: 'LectureView',
        component: () => import("@/views/ListView2"),
      },
      {
        path: '/lecture/:lecture_id/study',
        name: 'Study',
        component: () => import("@/views/Study"),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login")
  },
  {
    path: '/lecture/:lecture_id/summary',
    name: 'Summary',
    component: () => import("@/views/Summary")
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// Navigation Guard for Course
router.beforeEach((to, from, next) => {
  if (to.name === 'CourseView') {
    const courseId = to.params.id;
    if (store.state.courseIds.includes(courseId)) {
      next();
    } else {
      const baseUrl = process.env.VUE_APP_PAGES_URL;
      axios.get(`${baseUrl}/courses/get/${courseId}`, { withCredentials: true }).then(response => {
        if (response.data.id) {
          next();
        }
      }).catch(error => {
        if (error.response && error.response.status === 404) {
          next('/home'); // Redirect to home if a 404 error is received
        } else {
          console.error(error); // Log other errors
        }
      })
    }
  } else {
    next();
  }
})

// Navigation Guard for Lecture
router.beforeEach((to, from, next) => {
  if (to.name === 'LectureView') {
    const lectureId = to.params.id;
    if (store.state.lectureIds.includes(lectureId)) {
      next();
    } else {
      const baseUrl = process.env.VUE_APP_PAGES_URL;
      axios.get(`${baseUrl}/lectures/get/${lectureId}`, { withCredentials: true }).then(response => {
        if (response.data.id) {
          next();
        }
      }).catch(error => {
        if (error.response && error.response.status === 404) {
          next('/home'); // Redirect to home if a 404 error is received
        } else {
          console.error(error); // Log other errors
        }
      })
    }
  } else {
    next();
  }
})


export default router
